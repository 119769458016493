@font-face {
  font-family: "fmp-custom-fonts";
  src: url('../fonts/fmp-custom-fonts.eot?+XXMVjsklgb+Q44qkLpZ6i4QqDI=');
  src: url('../fonts/fmp-custom-fonts.eot?#iefix#+XXMVjsklgb+Q44qkLpZ6i4QqDI=') format('eot'),
  url('../fonts/fmp-custom-fonts.woff2?+XXMVjsklgb+Q44qkLpZ6i4QqDI=') format('woff2'),
  url('../fonts/fmp-custom-fonts.woff?+XXMVjsklgb+Q44qkLpZ6i4QqDI=') format('woff'),
  url('../fonts/fmp-custom-fonts.ttf?+XXMVjsklgb+Q44qkLpZ6i4QqDI=') format('truetype'),
  url('../fonts/fmp-custom-fonts.svg?+XXMVjsklgb+Q44qkLpZ6i4QqDI=#fmp-custom-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="custom-fa-"], [class*=" custom-fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fmp-custom-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-fa-a-add:before {
  content: "\EA01"
}
.custom-fa-anticipation:before {
  content: "\EA02"
}
.custom-fa-arrow:before {
  content: "\EA03"
}
.custom-fa-avatar-o:before {
  content: "\EA04"
}
.custom-fa-avatar-roof-o:before {
  content: "\EA05"
}
.custom-fa-b-meeting:before {
  content: "\EA06"
}
.custom-fa-briefcase-o:before {
  content: "\EA07"
}
.custom-fa-briefcase:before {
  content: "\EA08"
}
.custom-fa-coasting:before {
  content: "\EA09"
}
.custom-fa-cross-o:before {
  content: "\EA0A"
}
.custom-fa-cross:before {
  content: "\EA0B"
}
.custom-fa-cruise-control:before {
  content: "\EA0C"
}
.custom-fa-driver-evaluation:before {
  content: "\EA0D"
}
.custom-fa-eye-o:before {
  content: "\EA0E"
}
.custom-fa-eye:before {
  content: "\EA0F"
}
.custom-fa-factory-o:before {
  content: "\EA10"
}
.custom-fa-factory:before {
  content: "\EA11"
}
.custom-fa-handshake-o:before {
  content: "\EA12"
}
.custom-fa-handshake:before {
  content: "\EA13"
}
.custom-fa-headset:before {
  content: "\EA14"
}
.custom-fa-hill-driving:before {
  content: "\EA15"
}
.custom-fa-idling:before {
  content: "\EA16"
}
.custom-fa-ignition-off:before {
  content: "\EA17"
}
.custom-fa-ignition-unknown:before {
  content: "\EA18"
}
.custom-fa-key:before {
  content: "\EA19"
}
.custom-fa-location-pointer-o:before {
  content: "\EA1A"
}
.custom-fa-location-pointer:before {
  content: "\EA1B"
}
.custom-fa-lock-o:before {
  content: "\EA1C"
}
.custom-fa-lock:before {
  content: "\EA1D"
}
.custom-fa-map-icon-bknd:before {
  content: "\EA1E"
}
.custom-fa-menu-dots:before {
  content: "\EA1F"
}
.custom-fa-pen-o:before {
  content: "\EA20"
}
.custom-fa-pen:before {
  content: "\EA21"
}
.custom-fa-pin-o:before {
  content: "\EA22"
}
.custom-fa-pin:before {
  content: "\EA23"
}
.custom-fa-scale-up:before {
  content: "\EA24"
}
.custom-fa-speechbubble-o:before {
  content: "\EA25"
}
.custom-fa-speechbubble:before {
  content: "\EA26"
}
.custom-fa-speeding:before {
  content: "\EA27"
}
.custom-fa-tacho-available:before {
  content: "\EA28"
}
.custom-fa-tacho-driving:before {
  content: "\EA29"
}
.custom-fa-tacho-rest:before {
  content: "\EA2A"
}
.custom-fa-tacho-work:before {
  content: "\EA2B"
}
.custom-fa-tool-o:before {
  content: "\EA2C"
}
.custom-fa-tool:before {
  content: "\EA2D"
}
.custom-fa-trailer:before {
  content: "\EA2E"
}
.custom-fa-truck-coupled:before {
  content: "\EA2F"
}
.custom-fa-truck:before {
  content: "\EA30"
}
.custom-fa-unlock-o:before {
  content: "\EA31"
}
.custom-fa-unlock:before {
  content: "\EA32"
}
.custom-fa-vehicle-performance:before {
  content: "\EA33"
}
.custom-fa-wrench-circle:before {
  content: "\EA34"
}
.custom-fa-group:before {
  content: "\EA35"
}
.custom-fa-group-o:before {
  content: "\EA36"
}
.custom-fa-alert:before {
  content: "\EA39"
}
.custom-fa-clock:before {
  content: "\EA40"
}
.custom-fa-associate:before {
  content: "\EA41"
}
.custom-fa-notification:before {
  content: "\EA42"
}
.custom-fa-notification-active:before {
  content: "\EA43"
}
.custom-fa-circle-alert:before {
  content: "\EA44"
}
.custom-fa-circle-check:before {
  content: "\EA45"
}
.custom-fa-accident-roadwork:before {
  content: "\EA48"
}
.custom-fa-redo:before {
  content: "\EA49"
}
.custom-fa-write:before {
  content: "\EA50"
}
.custom-fa-image:before {
  content: "\EA51"
}
.custom-fa-compass:before {
  content: "\EA52"
}
.custom-fa-design:before {
  content: "\EA53"
}
.custom-fa-map:before {
  content: "\EA54"
}
.custom-fa-show-clusters:before {
  content: "\EA55"
}
.custom-fa-calendar:before {
  content: "\EA56"
}
.custom-fa-truck-stationary:before {
  content: "\EA57"
}
.custom-fa-truck-coupled-stationary:before {
  content: "\EA58"
}
.custom-fa-trailer-stationary:before {
  content: "\EA59"
}
.custom-fa-search-icon:before {
  content: "\EA60"
}
.custom-fa-ui-repairs:before {
  content: "\EA61"
}
.custom-fa-driver-evaluation-2:before {
  content: "\EA62"
}
.custom-fa-add-contact:before {
  content: "\EA63"
}
.custom-fa-vehicle-performance-2:before {
  content: "\EA64"
}
.custom-fa-customer-support-white:before {
  content: "\EA65"
}
