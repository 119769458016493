/*
 * Colors as defined in uxpin
 * https://app.uxpin.com/design-system/06f70c80e5f566b5ec18/colors
 */

$uxpin-white-color: #ffffff;
$uxpin-default-color: #94999d;
$uxpin-info-color: #4a8ba4;
$uxpin-black-color: #242424;
$tab-border: #ddd;

// INTERACTION COLORS
$interaction-warning: #f2855f;

// MAIN BRAND COLORS
$color-brand-01: #041e42;
$color-brand-03: #d6001c;

// font families
$scania-condensed-bold: 'Scania Sans Semi Condensed Bold';
$scania-condensed: 'Scania Sans Semi Condensed';
$scania-headline: 'Scania Sans Headline';

//margin-top after new nav bar
$padding-top: 35px;

//sdds variables
$sdds-border-radius: 4px;
$sdds-body-font-size: 14px;
$sdds-body-font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;

$sp-colors: (
  danger: var(--sdds-red-600),
  default: var(--sdds-grey-600),
  info: var(--sdds-blue-400),
  success: var(--sdds-positive),
  warning: var(--sdds-warning),
  primary: var(--sdds-blue-600)
);
