// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@scania/tegel/dist/tegel/tegel.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$administration-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$administration-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$administration-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$administration-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $administration-primary,
      accent: $administration-accent,
      warn: $administration-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($administration-theme);

@import '@scania/colour/dist/scss/colour';
@import '@scania/grid/dist/scss/grid';

@import 'assets/scss/font-awesome.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-toastr/toastr';

@import 'assets/scss/variables';
@import 'assets/scss/fmp-custom-fonts';

:root,
html {
  font-size: 10px !important;
}

.app > app-root {
  height: auto;
}

.panel .panel-body,
.panel .portlet-application {
  padding: 30px 40px;
}

.panel {
  margin-bottom: 15px;

  &-default {
    border: 1px solid $tab-border;
    background-color: $uxpin-white-color;
  }
}

.help-block {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', arial, helvetica, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: var(--sdds-spacing-element-4);
  color: var(--sdds-textfield-helper);

  &.label-warning {
    color: var(--sdds-textfield-helper-error);
  }
}

// .app[pageState='login'] > app-root {
//   /*height: 100vh;*/
// }

:root, body {
  background-color: #f7f7f7;
  font-family: $sdds-body-font-family;
  font-size: 1.4rem;
}

section.page-container {
  padding: $padding-top 0;
}

.sss-navbar {
  z-index: 1001 !important;
}

.sss-navbar__side-menu--expanded {
  z-index: 1002 !important;
}

.sss-navbar {
  z-index: 1;
}

a.sss-navbar-menu-nav-link {
  text-decoration: none;
}

.container {
  margin: 40px auto;
}

.container-fluid {
  width: 100%;
}

.panel-title .toggle-button {
  cursor: pointer;
  font-weight: 400;
  line-height: 0.5em;
}

button {
  outline: none !important;
}

[class^='custom-fa-'],
[class*=' custom-fa-'] {
  font-family: 'fmp-custom-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app .btn-link.btn-info {
  font-weight: bold;
  text-decoration: none;
  color: $uxpin-info-color;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.app .btn-link.btn-info:hover,
.app .btn-link.btn-info:active,
.app .btn-link.btn-info.active {
  color: #6cabc3;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none;
}

//colours
.control-label,
.icon-label {
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-left: 2px;
    display: flex;
    align-items: center;
    top: 1px;

    &.cui-scania-info-circle {
      position: relative;
    }
  }
}

.tooltip {
  opacity: 1 !important;
  font-size: 12px;
}

.tooltip-inner {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
  color: var(--sdds-white);
  background-color: var(--sdds-grey-800);
  padding: 10px;
  border-radius: 4px;
  font-weight: normal;
  text-align: left;
}

.app label,
.control-label,
.app caption {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: var(--sdds-textfield-label-color) !important;
}

//tables.less

.btn + .btn,
.btn + .btn-group {
  margin-left: 7px;
  float: none;
}

.table i {
  white-space: nowrap;
  margin-right: 5px;
}

.location-pin-container {
  position: absolute;
  top: -31px;
  left: -12px;

  .location-pin {
    box-sizing: border-box;
    background: transparent;
    display: inline-block;
    border-radius: 14px 14px 14px 0;
    width: 24px;
    height: 24px;
    border: 7px solid #418bca;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .shadow {
    box-sizing: border-box;
    display: block;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 5px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    -o-transform: rotate(-55deg);
    transform: rotate(-55deg);
    z-index: -2;
  }
}

a {
  cursor: pointer;
}

.table > tbody + tbody {
  border-top: none;
}

.pull-right {
  float: right;
}

dd,
dt {
  line-height: 1.42857143;
}

html,
body {
  height: 100%;
}

.row-spacing {
  & .row + .row {
    margin-top: 1em;
  }
}

/* Toaster css */
body .toast-container .ngx-toastr {
  background: $uxpin-white-color;
  color: #53565a;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  line-height: 2.2;
}

.toast-container .ngx-toastr.toast-success {
  border-left: 6px solid #51a351;
}

.toast-container .ngx-toastr.toast-error {
  border-left: 6px solid #cc1040;
}

.toast-container .ngx-toastr.toast-info {
  border-left: 6px solid $uxpin-info-color;
}

.toast-container .ngx-toastr.toast-warning {
  border-left: 6px solid $interaction-warning;
}
